import React, { useEffect, useRef, useState } from 'react';
import style from './PaymentComponent.module.scss';
import avatar from "../../assets/images/avatars/default-avatar.png";
import companyAvatar from "../../assets/images/company-logo.png";
import { useDispatch, useSelector } from "react-redux";
import UserService from "../../services/UserService";
import { Amount, PaymentEmail } from "../../redux/slices/paymentSlice";
import {Avatar, IconButton, LinearProgress, linearProgressClasses} from "@mui/material";
import Payment from "./payment/Payment";
import Button from "@mui/material/Button";
import DecimalInput from "../DecimalInput";
import { useParams } from "react-router-dom";
import PaymentRating from "./PaymentRating";
import StarIcon from "@mui/icons-material/Star";
import PaymentErrorModal from "../PaymentErrorModal";
import { styled } from "@mui/material/styles";
import {IsLoading} from "../../redux/slices/userSlice";
import GoodDrink from "../profile-components/profile-edit/survey-icon/GoodDrink";
import CleanIcon from "../profile-components/profile-edit/survey-icon/CleanIcon";
import GoodFood from "../profile-components/profile-edit/survey-icon/GoodFood";
import GoodMusic from "../profile-components/profile-edit/survey-icon/GoodMusic";
import GoodService from "../profile-components/profile-edit/survey-icon/GoodService";
import LikeEuro from "../profile-components/profile-edit/survey-icon/LikeEuro";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 5,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: "#F0F2F9",
        ...theme.applyStyles("dark", {
            backgroundColor: theme.palette.grey[800],
        }),
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: "#0068F4",
        ...theme.applyStyles("dark", {
            backgroundColor: "#0068F4",
        }),
    },
}));

function PaymentComponent(props) {
    const { rating, review } = useSelector(state => state.currency);
    const { userRating } = useSelector(state => state.user);
    const textareaRef = useRef(null);
    const [comment, setComment] = useState("");
    const dispatch = useDispatch();
    const [paymentUser, setPaymentUser] = useState([]);
    const { userIsRating, userIsReview, userFirstName, userLastName, userName, userProfession, userWelcomePhrase, userImageName, userCompanyLogo, totalAmount, collected, good_drinks, clean_premises, good_food, good_music, good_service, like_euro, minAmount } = paymentUser;
    const { id } = useParams();
    const [originalName, setOriginalName] = useState("");
    const [percent, setPercent] = useState(0);
    const howMuchIsLeft = totalAmount - collected;
    const [goodDrinks, setGoodDrinks] = useState(false);
    const [cleanPremises, setCleanPremises] = useState(false);
    const [goodFood, setGoodFood] = useState(false);
    const [goodMusic, setGoodMusic] = useState(false);
    const [goodService, setGoodService] = useState(false);
    const [likeEuro, setLikeEuro] = useState(false);
    const avatarPath = `/media/${userImageName}`
    const companyAvatarPath = `/media/company/${userCompanyLogo}`
    const [goodDrinkCount, setGoodDrinkCount] = useState(0);
    const [cleanPremisesCount, setCleanPremisesCount] = useState(0);
    const [goodFoodCount, setGoodFoodCount] = useState(0);
    const [goodMusicCount, setGoodMusicCount] = useState(0);
    const [goodServiceCount, setGoodServiceCount] = useState(0);
    const [likeEuroCount, setLikeEuroCount] = useState(0);

    const handleGoodDrinkChange = () => {
        setGoodDrinks(!goodDrinks)
        setGoodDrinkCount(prevCount => prevCount + 1)
    }
    const handleCleanPremisesChange = () => {
        setCleanPremises(!cleanPremises)
        setCleanPremisesCount(prevCount => prevCount + 1)
    }
    const handleGoodFoodChange = () => {
        setGoodFood(!goodFood)
        setGoodFoodCount(prevCount => prevCount + 1)
    }
    const handleGoodMusicChange = () => {
        setGoodMusic(!goodMusic)
        setGoodMusicCount(prevCount => prevCount + 1)
    }
    const handleGoodServiceChange = () => {
        setGoodService(!goodService)
        setGoodServiceCount(prevCount => prevCount + 1)
    }
    const handleLikeEuroChange = () => {
        setLikeEuro(!likeEuro)
        setLikeEuroCount(prevCount => prevCount + 1)
    }

    const fetchData = async () => {
        dispatch(IsLoading(true))
        try {
            if (id) {
                const result = await UserService.fetchUser(id);
                if (result.data && result.data.length > 0) {
                    const userData = result.data[0];
                    setPaymentUser(userData);
                    dispatch(PaymentEmail(userData.email));
                } else {
                    console.warn('No user data found for ID:', id);
                }
            }
        } catch (e) {
            console.log(e);
        }finally {
            dispatch(IsLoading(false))
        }
    };
    useEffect(() => {
        if (id) {
            fetchData();
        }
    }, [id]);
    useEffect(() => {
        if (totalAmount !== undefined && collected !== undefined) {
            const calculatedPercent = (collected / totalAmount) * 100;
            setPercent(calculatedPercent);
        }
    }, [totalAmount, collected]);
    useEffect(() => {
        if (paymentUser) {
            const firstNameLastName = `${userFirstName || ''} ${userLastName || ''}`.trim();
            setOriginalName(firstNameLastName || userName);
        }
    }, [paymentUser]);
    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    }, [comment]);
    const customPriceChange = (e) => {
        const newPrice = parseFloat(e.target.innerText);
        if (!isNaN(newPrice)) {
            dispatch(Amount(newPrice));
        }
    };
    if (!paymentUser) {
        // return <Box sx={{width: '100%', position: "absolute"}}><CircularProgress /></Box>;
        return null;
    }

    return (
        <div className={style.payment_component + " bg-white"}>
            <PaymentErrorModal />
            <div className={style.payment_component_header}>
                <div className="d-flex">
                    <div className={style.user_image}>
                        <Avatar alt="avatar" src={avatarPath ? avatarPath : avatar} sx={{ width: 56, height: 56, borderRadius: "8px" }} />
                        {userRating > 0 && <div className={style.user_rating}><StarIcon /><span>{userRating}</span></div>}
                    </div>
                    <div className={style.user_data}>
                        <div className={style.user_name}>{originalName}</div>
                        <div className={style.user_profession}>{userProfession}</div>
                    </div>
                </div>
                {userCompanyLogo && <div className={style.user_company_logo}><img src={companyAvatarPath} alt="Company avatar"/></div>}
            </div>
            <div className={style.payment_bar}>
                <div className={style.user_welcome_text}>{userWelcomePhrase}</div>
                <span>
                    Recaudado&nbsp;{collected !== undefined ? collected.toLocaleString('en-US') : 0}€
                    de&nbsp;{totalAmount !== undefined ? totalAmount.toLocaleString('en-US') : 0}€
                    falta&nbsp;{howMuchIsLeft !== undefined ? howMuchIsLeft.toLocaleString('en-US') : 0}€
                </span>
                <BorderLinearProgress variant="determinate" value={percent}/>
            </div>
            <div className={style.payment_data}>
                <div className={style.payment_data_title}>Deja una propina</div>
                <div className={style.payment_elements}>
                    <div className={style.price_element}><DecimalInput currency="USD" locale="en-US" minAmount={minAmount}/></div>
                    <div className={style.price_element}>
                        <Button className={style.custom_price_btn} onClick={(e) => customPriceChange(e)}>1&#8364;</Button>
                        <Button className={style.custom_price_btn} onClick={(e) => customPriceChange(e)}>3&#8364;</Button>
                        <Button className={style.custom_price_btn} onClick={(e) => customPriceChange(e)}>5&#8364;</Button>
                        <Button className={style.custom_price_btn} onClick={(e) => customPriceChange(e)}>10&#8364;</Button>
                    </div>
                    <div className={style.rating_elements}>
                        {userIsRating === 1 &&
                            <>
                                <div className={style.payment_data_title}>Valora el servicio</div>
                                <div className={style.stars}>
                                    <PaymentRating/>
                                </div>
                            </>
                        }
                        {userIsReview === 1 &&
                            <div className={style.rating_comment}>
                                <textarea
                                    rows={1}
                                    placeholder="Comentario"
                                    ref={textareaRef}
                                    value={comment}
                                    onChange={(e) => setComment(e.target.value)}
                                ></textarea>
                            </div>
                        }
                    </div>
                    <div className={style.survey}>
                        {(good_drinks === 1 || clean_premises === 1 || good_food === 1 || good_music === 1 || good_service === 1 || like_euro === 1) &&
                            <div className={style.payment_data_title}>¿Marca que le ha gustado?</div>
                        }
                        <div className={style.survey_items}>
                            {good_drinks === 1 && <IconButton onClick={handleGoodDrinkChange} style={{transition: ".3s", transform: `scale(${goodDrinks ? "1.1" : "1"})`, color: goodDrinks ? "#0068F4" : "#A3ABB7"}}><GoodDrink width={42} active={goodDrinks}/><span>Buenas bebidas</span></IconButton>}
                            {clean_premises === 1 && <IconButton onClick={handleCleanPremisesChange} style={{transition: ".3s", transform: `scale(${cleanPremises ? "1.1" : "1"})`, color: cleanPremises ? "#0068F4" : "#A3ABB7"}}><CleanIcon width={42} active={cleanPremises}/><span>Local limpio</span></IconButton>}
                            {good_food === 1 && <IconButton onClick={handleGoodFoodChange} style={{transition: ".3s", transform: `scale(${goodFood ? "1.1" : "1"})`, color: goodFood ? "#0068F4" : "#A3ABB7"}}><GoodFood width={42} active={goodFood}/><span>Buena comida</span></IconButton>}
                            {good_music === 1 && <IconButton onClick={handleGoodMusicChange} style={{transition: ".3s", transform: `scale(${goodMusic ? "1.1" : "1"})`, color: goodMusic ? "#0068F4" : "#A3ABB7"}}><GoodMusic width={42} active={goodMusic} /><span>Buena musica</span></IconButton>}
                            {good_service === 1 && <IconButton onClick={handleGoodServiceChange} style={{transition: ".3s", transform: `scale(${goodService ? "1.1" : "1"})`, color: goodService ? "#0068F4" : "#A3ABB7"}}><GoodService width={42} active={goodService}/><span>Buen servicio</span></IconButton>}
                            {like_euro === 1 && <IconButton onClick={handleLikeEuroChange} style={{transition: ".3s", transform: `scale(${likeEuro ? "1.1" : "1"})`, color: likeEuro ? "#0068F4" : "#A3ABB7"}}><LikeEuro width={42} active={likeEuro}/><span>Buenos precios</span></IconButton>}
                        </div>
                    </div>
                </div>
                <Payment
                    goodDrinkCount={goodDrinkCount}
                    cleanPremisesCount={cleanPremisesCount}
                    goodFoodCount={goodFoodCount}
                    goodMusicCount={goodMusicCount}
                    goodServiceCount={goodServiceCount}
                    likeEuroCount={likeEuroCount}
                    comment={comment}
                />
            </div>
        </div>
    );
}

export default PaymentComponent;
