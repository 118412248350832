import React, {useEffect, useRef, useState} from 'react';
import style from './Header.module.scss'
import logo from '../../assets/images/logo.png'
import darkLogo from '../../assets/images/logo-dark-theme.png'
import {Offcanvas} from "react-bootstrap";
import Button from '@mui/material/Button';
import {HiMiniBars3} from "react-icons/hi2";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import AuthService from "../../services/AuthService";
import {Auth, RemoveUser} from "../../redux/slices/userSlice";
import {
    Box,
    ClickAwayListener,
    Grow,
    IconButton,
    LinearProgress,
    MenuItem,
    MenuList,
    Paper,
    Popper
} from "@mui/material";
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import {DarkMode} from "../../redux/slices/accountSettingsSlice";
import {useTranslation} from "react-i18next";
import DashboardIcon from '@mui/icons-material/Dashboard';
function Header(props) {
    const {user, auth, isLoading} = useSelector(state => state.user)
    const userID = user && user.id;
    const dispatch = useDispatch();
    const [openLang, setOpenLang] = useState(false);
    const anchorLangRef = useRef(null);
    const handleToggleLang = () => {
        setOpenLang((prevOpen) => !prevOpen);
    };
    const handleClosLang = (event) => {
        if (anchorLangRef.current && anchorLangRef.current.contains(event.target)) {
            return;
        }
        setOpenLang(false);
    };
    const location = useLocation();
    const isActiveLink = (path) => {
        return location.pathname === path;
    };
    const darkMode = useSelector((state) => state.settings.darkMode);
    const [show, setShow] = useState(false);
    const { i18n, t } = useTranslation();
    const currentLanguage = i18n.language;
    const localStorageToken = localStorage.getItem("user");
    const navigate = useNavigate()

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(!show);
    const logout = async () => {
        await AuthService.logout(userID);
        localStorage.removeItem('user');
        localStorage.removeItem('session');
        dispatch(Auth(false));
        navigate("/");
        dispatch(RemoveUser([]));
        handleClose()
    }
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const handleCloseSelect = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };
    const handleChangeMode = () => {
        dispatch(DarkMode(!darkMode))
        handleClose()
    }
    useEffect(()=>{
        if(darkMode){
            localStorage.setItem('darkMode', "true");
        }else {
            localStorage.setItem('darkMode', "false");
        }
    }, [darkMode])
    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        } else if (event.key === 'Escape') {
            setOpen(false);
        }
    }
    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    const language = (lng) => {
        i18n.changeLanguage(lng);
        setOpenLang(false);
    };
    const navigateToDashboard = () => {
        navigate("/profile")
    }
    return (
        <div className={darkMode ? style.header + " dark-section-bg" :  style.header} style={{borderBottomColor: darkMode && "#272727"}}>
            {isLoading && <Box sx={{width: '100%', position: "absolute"}}><LinearProgress/></Box>}
            <div className="container">
                <div className={style.header_items}>
                    <nav className={style.nav}>
                        {auth
                            ?
                            <div className={style.logo}><img src={darkMode ? darkLogo : logo} alt="logo"/></div>
                            :
                            <div className={style.logo}><Link to={auth ? "" : "/"}><img src={darkMode ? darkLogo : logo} alt="logo"/></Link></div>
                        }
                        <ul className={style.menu_list}>
                            <li className={style.menu_item}>
                                <Link to={auth ? "/profile" : "/about"}>
                                    <Button
                                        variant="link"
                                        style={{ color: darkMode ? "#fff" : undefined }}
                                        className={
                                        `   ${isActiveLink(auth ? "/profile" : "/about") ? style.active : ''} 
                                            ${darkMode && isActiveLink(auth ? "/profile" : "/about") ? style.darkModeActive : ""}
                                        `
                                        }
                                    >
                                        {auth ? t("Profile") : t("About Us")}
                                    </Button>
                                </Link>
                            </li>
                            <li className={style.menu_item}>
                                <Link to={auth ? "/profile/transactions" : "/start"}>
                                    <Button
                                        variant="link"
                                        style={{ color: darkMode ? "#fff" : undefined }}
                                        className={
                                        `   ${isActiveLink(auth ? "/profile/transactions" : "/start") ? style.active : ''} 
                                            ${darkMode && isActiveLink(auth ? "/profile/transactions" : '/start') ? style.darkModeActive : ""}
                                        `
                                        }
                                    >
                                        {auth ? t("Transactions") : t("How to start")}
                                    </Button>
                                </Link>
                            </li>
                            {auth &&
                                <li className={style.menu_item}>
                                    <Link to={"/profile/transfer"}>
                                        <Button
                                            variant="link"
                                            style={{ color: darkMode ? "#fff" : undefined }}
                                            className={
                                                ` ${isActiveLink(auth ? "/profile/transfer" : '') ? style.active : ''} 
                                                  ${darkMode && isActiveLink(auth ? "/profile/transfer" : '') ? style.darkModeActive : ""}
                                                `
                                            }
                                        >
                                            {t("Transfer")}
                                        </Button>
                                    </Link>
                                </li>}
                            <li className={style.menu_item}>
                                <Link to={auth ? "/profile/print" : "/staff"}>
                                    <Button
                                        variant="link"
                                        style={{color: darkMode ? "#fff" : undefined}}
                                        className={
                                            ` ${isActiveLink(auth ? "/profile/print" : '/staff') ? style.active : ''} 
                                            ${darkMode && isActiveLink(auth ? "/profile/print" : '/staff') ? style.darkModeActive : ""}
                                            `
                                        }
                                    >{auth ? t("Print") : t("For staff")}
                                    </Button>
                                </Link>
                            </li>
                            <li className={style.menu_item}>
                                {auth ?
                                    // <div className={style.account_menu_select}>
                                    //         <Button
                                    //             ref={anchorRef}
                                    //             id="composition-button"
                                    //             aria-controls={open ? 'composition-menu' : undefined}
                                    //             aria-expanded={open ? 'true' : undefined}
                                    //             aria-haspopup="true"
                                    //             onClick={handleToggle}
                                    //             variant="link"
                                    //             className={open && style}
                                    //         >
                                    //             {t("Company")}
                                    //         </Button>
                                    //         <Popper
                                    //             open={open}
                                    //             anchorEl={anchorRef.current}
                                    //             role={undefined}
                                    //             placement="bottom-start"
                                    //             transition
                                    //             disablePortal
                                    //         >
                                    //             {({TransitionProps, placement}) => (
                                    //                 <Grow
                                    //                     {...TransitionProps}
                                    //                     style={{
                                    //                         transformOrigin:
                                    //                             placement === 'bottom-start' ? 'left top' : 'left bottom',
                                    //                     }}
                                    //                 >
                                    //                     <Paper>
                                    //                         <ClickAwayListener onClickAway={handleCloseSelect}>
                                    //                             <MenuList
                                    //                                 autoFocusItem={open}
                                    //                                 id="composition-menu"
                                    //                                 aria-labelledby="composition-button"
                                    //                                 onKeyDown={handleListKeyDown}
                                    //                             >
                                    //                                 <MenuItem onClick={handleCloseSelect}><Link to="/company/staff">{t(Employees)}</Link></MenuItem>
                                    //                                 {/*<MenuItem onClick={handleCloseSelect}><Link to="/company/ratings">{t("Employee Ratings")}</Link></MenuItem>*/}
                                    //                                 <MenuItem onClick={handleCloseSelect}><Link to="/company/transactions">{t("All Transactions")}</Link></MenuItem>
                                    //                             </MenuList>
                                    //                         </ClickAwayListener>
                                    //                     </Paper>
                                    //                 </Grow>
                                    //             )}
                                    //         </Popper>
                                    //
                                    // </div>
                                    ""
                                    :
                                    <Link to="/business">
                                        <Button
                                            variant="link"
                                            style={{color: darkMode && "#fff"}}
                                            className={
                                                `${isActiveLink("/business") ? style.active : ''} ${darkMode && isActiveLink("/business") ? style.darkModeActive : ""}`
                                            }
                                        >
                                            {t("For business")}
                                        </Button>
                                    </Link>
                                }
                            </li>
                        </ul>
                    </nav>
                    <div className={style.header_right}>
                        <div className={style.language}>
                            <Button
                                ref={anchorLangRef}
                                id="composition-button"
                                aria-controls={openLang ? 'composition-menu' : undefined}
                                aria-expanded={openLang ? 'true' : undefined}
                                aria-haspopup="true"
                                onClick={handleToggleLang}
                                style={{color: darkMode && "#fff"}}
                            >
                                {currentLanguage === "en" ? "ENG" : currentLanguage === "es" ? "ESP" : "CAT"}
                            </Button>
                            <Popper
                                open={openLang}
                                anchorEl={anchorLangRef.current}
                                role={undefined}
                                placement="bottom-start"
                                transition
                                disablePortal
                            >
                                {({TransitionProps, placement}) => (
                                    <Grow
                                        {...TransitionProps}
                                        style={{
                                            transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
                                            backgroundColor: darkMode && "#272727",
                                        }}

                                    >
                                        <Paper>
                                            <ClickAwayListener onClickAway={handleClosLang}>
                                                <MenuList
                                                    autoFocusItem={openLang}
                                                    id="composition-menu"
                                                    aria-labelledby="composition-button"
                                                    onKeyDown={handleListKeyDown}
                                                >
                                                    {(currentLanguage === "es" || currentLanguage === "ca") && (<MenuItem onClick={() => language("en")} style={{ color: darkMode ? "#fff" : "inherit" }}>ENG</MenuItem>)}
                                                    {(currentLanguage === "en" || currentLanguage === "ca") && (<MenuItem onClick={() => language("es")} style={{ color: darkMode ? "#fff" : "inherit" }}>ESP</MenuItem>)}
                                                    {(currentLanguage === "en" || currentLanguage === "es") && (<MenuItem onClick={() => language("ca")} style={{ color: darkMode ? "#fff" : "inherit" }}>CAT</MenuItem>)}
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        </div>
                        <div className={style.header_right_btns}>
                            {auth
                                ?
                                <Button
                                    variant="contained"
                                    className={style.header_right_button}
                                    onClick={localStorageToken && window.location.pathname.split("/")[1] !== "profile" ? navigateToDashboard : logout}
                                >
                                    {localStorageToken && window.location.pathname.split("/")[1] !== "profile" ? <><DashboardIcon fontSize="small"/>&nbsp;{t("Dashboard")}</> : <>{t("Sign out")}&nbsp;<LogoutIcon fontSize="small" /></>}
                                </Button>
                                :
                                <Link to="/sign-in" className={style.header_right_button + " primary"}><Button variant="contained"><LoginIcon fontSize="small"/>&nbsp;{t("Sign in")}</Button></Link>
                            }
                            <IconButton aria-label="mode" onClick={handleChangeMode} style={{backgroundColor: "transparent"}}>{darkMode ? <Brightness7Icon style={{color: darkMode && "#fff"}}/> : <DarkModeIcon sx={{color: "#272727"}}/>}</IconButton>
                        </div>
                        <div className={style.nav}>
                            <HiMiniBars3 className={darkMode ? style.burger + " light-text" : style.burger} onClick={handleShow} size={20}/>
                            <Offcanvas show={show} onHide={handleClose}>
                                <Offcanvas.Body show={open} className={darkMode ? style.mobile_nav + " dark-block-bg light-text" : style.mobile_nav}>
                                    <ul className={style.menu_list}>
                                        <li className={style.menu_item}>
                                            <Link to={auth ? "/profile" : "/about"}>
                                                <Button
                                                    variant="link"
                                                    style={{color: darkMode && "#fff"}}
                                                    className={
                                                        ` ${isActiveLink(auth ? "/profile" : "/about") ? style.active : ''} 
                                                          ${darkMode && isActiveLink(auth ? "/profile" : "/about") ? style.darkModeActive : ""}
                                                        `
                                                    }
                                                    onClick={handleClose}>
                                                    {auth ? t("Profile") : t("About us")}
                                                </Button>
                                            </Link>
                                        </li>
                                        <li className={style.menu_item}>
                                            <Link to={auth ? "profile/transactions" : "/start"}><Button variant="link" style={{color: darkMode && "#fff"}} className={isActiveLink(auth ? "/profile/transactions" : '/start') ? style.active && darkMode && style.darkModeActive : ''} onClick={handleClose}>{auth ? t("Transactions") : t("How to start")}</Button></Link>
                                        </li>
                                        {auth && <li className={style.menu_item}><Link to={"/profile/transfer"}>
                                            <Button variant="link" style={{color: darkMode && "#fff"}} className={isActiveLink(auth ? "/profile/transfer" : '') ? style.active && darkMode && style.darkModeActive : ''} onClick={handleClose}>{t("Transfer")}</Button></Link>
                                        </li>}
                                        <li className={style.menu_item}><Link to={auth ? "/profile/print" : "/staff"}>
                                            <Button variant="link" style={{color: darkMode && "#fff"}} onClick={handleClose} className={isActiveLink(auth ? "/profile/print" : '/staff') ? style.active && darkMode && style.darkModeActive : ''}>{auth ? t("Print") : t("For staff")}</Button></Link>
                                        </li>
                                        <li className={style.menu_item}>
                                            {auth ?
                                                // <div className={style.account_menu_select}>
                                                //     <Button
                                                //         ref={anchorRef}
                                                //         id="composition-button"
                                                //         aria-controls={open ? 'composition-menu' : undefined}
                                                //         aria-expanded={open ? 'true' : undefined}
                                                //         aria-haspopup="true"
                                                //         onClick={handleToggle}
                                                //         variant="link"
                                                //         style={{color: darkMode && "#fff"}}
                                                //     >
                                                //         Company
                                                //     </Button>
                                                //     <Popper
                                                //         open={open}
                                                //         anchorEl={anchorRef.current}
                                                //         role={undefined}
                                                //         placement="bottom-start"
                                                //         transition
                                                //         disablePortal
                                                //         style={{zIndex: 10}}
                                                //     >
                                                //         {({TransitionProps, placement}) => (
                                                //             <Grow
                                                //                 {...TransitionProps}
                                                //                 style={{
                                                //                     transformOrigin:
                                                //                         placement === 'bottom-start' ? 'left top' : 'left bottom',
                                                //                 }}
                                                //             >
                                                //                 <Paper>
                                                //                     <ClickAwayListener onClickAway={handleClose}>
                                                //                         <MenuList
                                                //                             autoFocusItem={open}
                                                //                             id="composition-menu"
                                                //                             aria-labelledby="composition-button"
                                                //                             onKeyDown={handleListKeyDown}
                                                //                         >
                                                //                             <MenuItem onClick={handleClose}><Link to="/company/staff">Employees</Link></MenuItem>
                                                //                             {/*<MenuItem onClick={handleClose}><Link to="/company/ratings">Employee Ratings</Link></MenuItem>*/}
                                                //                             <MenuItem onClick={handleClose}><Link
                                                //                                 to="/company/transactions">All
                                                //                                 Transactions</Link></MenuItem>
                                                //                         </MenuList>
                                                //                     </ClickAwayListener>
                                                //                 </Paper>
                                                //             </Grow>
                                                //         )}
                                                //     </Popper>
                                                // </div>
                                                ""
                                                :
                                                <Link to="/business"><Button variant="link" style={{color: darkMode && "#fff"}} className={isActiveLink(auth ? '/business' : "") ? style.active && darkMode && style.darkModeActive : ''}>{t("For business")}</Button></Link>
                                            }
                                        </li>
                                        <IconButton aria-label="mode" onClick={handleChangeMode} style={{backgroundColor: "transparent"}}>{darkMode ? <Brightness7Icon style={{color: darkMode && "#fff"}}/> : <DarkModeIcon sx={{color: "#272727"}}/>}</IconButton>
                                    </ul>
                                    {auth
                                        ?
                                        <Button
                                            variant="contained"
                                            className={style.header_right_button}
                                            onClick={localStorageToken && window.location.pathname.split("/")[1] !== "profile" ? navigateToDashboard : logout}
                                        >
                                            {localStorageToken && window.location.pathname.split("/")[1] !== "profile" ? <><DashboardIcon fontSize="small"/>&nbsp;{t("Dashboard")}</> : <>{t("Sign out")}&nbsp;<LogoutIcon fontSize="small" /></>}
                                        </Button>
                                        :
                                        <Link to="/sign-in"><Button variant="contained" className={style.header_right_button + " primary"} onClick={handleClose}><LoginIcon fontSize="small"/>&nbsp;{t("Sign in")}</Button></Link>
                                    }
                                </Offcanvas.Body>
                            </Offcanvas>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header;